export default [
	{
		key: "logotype",
		label: "Logo",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "name",
		label: "Nombre",
		visible: true,
		thClass: "text-center",
	},

	{
		key: "description",
		label: "Descripcion",
		visible: true,
		thClass: "text-center",
	},

	// {
	// 	key: "status",
	// 	label: "estado",
	// 	visible: true,
	// 	thClass: "text-center",
	// },

	{
		key: "created_by",
		label: "Creado por",
		visible: true,
		thClass: "text-center",
	},

	{
		key: "actions",
		label: "Acciones",
		thClass: "text-center",
		visible: true,
	},
]
